<template>
  <div class="stats-container stats-viewer-users-overview">
    <div class="stats-container-header">
      <h3> User Gender <i><small> (total) </small></i>  </h3>
    </div>
    <div class="stats-container-content">
      <v-table v-if="this.metrics.length > 0">
        <thead>
        <tr>
          <th class="text-left">
            Gender
          </th>
          <th class="text-left">
            Count
          </th>
          <th class="text-left">
            %
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(val, key) in this.data.metricBuckets" :key="key">
          <td>{{ key.split('').map((x, i) => i === 0 ? x.toUpperCase() : x).join('') }} </td>
          <td>{{ val.count }} </td>
          <td>{{ Math.round(((val.count / this.data.totalCount)*10000))/100 }}%</td>
        </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatsUserGender',
  data() {
    return {
      data: {
        metricBuckets: {
          male: { count: 0 },
          female: { count: 0 },
          x: { count: 0 },
        },
        totalCount: 0,
      },
    };
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
    metrics: {
      type: Array,
    },
  },
  methods: {},
  mounted() {
    this.data.metricBuckets = {
      male: { count: 0 },
      female: { count: 0 },
      x: { count: 0 },
    };

    this.metrics.forEach((metric) => {
      const gender = parseInt(metric.dimension_value, 10);
      const count = parseInt(metric.metric_value, 10);

      if (gender === 1) {
        this.data.metricBuckets.male.count += count;
      } else if (gender === 2) {
        this.data.metricBuckets.female.count += count;
      } else if (gender === 3) {
        this.data.metricBuckets.x.count += count;
      }
    });
    this.data.totalCount = Object.values(this.data.metricBuckets)
      .reduce((sum, x) => sum + x.count, 0);
  },
});
</script>

<style scoped>

</style>

/* eslint-disable */
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import colors from 'vuetify/lib/util/colors';

// Vuetify
import { createVuetify } from 'vuetify';

const thisTheme = {
  dark: false,
  colors: {
    background: colors.grey.lighten5,
  }
};

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'thisTheme',
    themes: {
      thisTheme,
    }
  }
});

<template>
  <div class="stats-container stats-viewer-users-overview">
    <div class="stats-container-header">
      <h3> User age <i><small> (total) </small></i> </h3>
    </div>
    <div class="stats-container-content">
      <v-table v-if="this.metrics.length > 0">
        <thead>
        <tr>
          <th class="text-left">
            Age
          </th>
          <th class="text-left">
            Count
          </th>
          <th class="text-left">
            %
          </th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(val, key) in this.data.metricBuckets" :key="key">
            <td>{{key}}</td>
            <td>{{ val.count }}</td>
            <td>{{ Math.round(((val.count / this.data.totalCount)*10000))/100 }}%</td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StatsMixin from '@/mixins/StatsMixin.vue';

export default defineComponent({
  name: 'StatsUserAgeCount',
  data() {
    return {
      data: {
        totalCount: 0,
        metricBuckets: {
          '18-24': { count: 0 },
          '25-29': { count: 0 },
          '30-34': { count: 0 },
          '35-39': { count: 0 },
          '40-44': { count: 0 },
          '45-49': { count: 0 },
          '50-59': { count: 0 },
          '60-69': { count: 0 },
          '70-79': { count: 0 },
          '80-89': { count: 0 },
          '90+': { count: 0 },
        },
      },
    };
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
    metrics: {
      type: Array,
    },
  },
  mixins: [
    StatsMixin,
  ],
  methods: {
  },
  mounted() {
    this.data.totalCount = 0;
    this.data.metricBuckets = {
      '18-24': { count: 0 },
      '25-29': { count: 0 },
      '30-34': { count: 0 },
      '35-39': { count: 0 },
      '40-44': { count: 0 },
      '45-49': { count: 0 },
      '50-59': { count: 0 },
      '60-69': { count: 0 },
      '70-79': { count: 0 },
      '80-89': { count: 0 },
      '90+': { count: 0 },
    };

    this.metrics.forEach((metric) => {
      const age = parseInt(metric.dimension_value, 10);
      const count = parseInt(metric.metric_value, 10);

      if (age > 18 && age <= 24) {
        this.data.metricBuckets['18-24'].count += count;
      } else if (age >= 25 && age <= 29) {
        this.data.metricBuckets['25-29'].count += count;
      } else if (age >= 30 && age <= 34) {
        this.data.metricBuckets['30-34'].count += count;
      } else if (age >= 35 && age <= 39) {
        this.data.metricBuckets['35-39'].count += count;
      } else if (age >= 40 && age <= 44) {
        this.data.metricBuckets['40-44'].count += count;
      } else if (age >= 45 && age <= 49) {
        this.data.metricBuckets['45-49'].count += count;
      } else if (age >= 50 && age <= 59) {
        this.data.metricBuckets['50-59'].count += count;
      } else if (age >= 60 && age <= 69) {
        this.data.metricBuckets['60-69'].count += count;
      } else if (age >= 70 && age <= 70) {
        this.data.metricBuckets['70-79'].count += count;
      } else if (age >= 80 && age <= 89) {
        this.data.metricBuckets['80-89'].count += count;
      } else {
        this.data.metricBuckets['90+'].count += count;
      }
    });
    this.data.totalCount = Object.values(this.data.metricBuckets)
      .reduce((sum, x) => sum + x.count, 0);
  },
});
</script>

<style scoped>

</style>

<template>
  <div class="stats-container stats-viewer-users-overview">
    <div class="stats-container-header">
      <h3> {{this.title}} <i><small> ({{ this.timespan ?? 'Total' }}) </small></i>  </h3>
    </div>
    <div class="stats-container-content">
      <v-table v-if="this.metrics.length > 0">
        <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Count
          </th>
          <th class="text-left">
            %
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="metric in this.sortedMetrics" :key="metric.dimension_value">
          <td>{{ metric.dimension_value }} </td>
          <td>{{ metric.metric_value }} </td>
          <td>{{ Math.round(((metric.metric_value / this.totalCount)*10000))/100 }}%</td>
        </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatsGeneric',
  data() {
    return {
      data: {
        metricBuckets: {
          male: { count: 0 },
          female: { count: 0 },
          x: { count: 0 },
        },
        totalCount: 0,
      },
    };
  },
  computed: {
    totalCount() {
      let totalCount = 0;
      this.metrics.forEach((metric) => {
        totalCount += metric.metric_value;
      });
      return totalCount;
    },
    sortedMetrics() {
      return this.metrics
        .filter((x) => !!x.dimension_value && parseInt(x.dimension_value, 10) !== 0)
        .sort((a, b) => (a.metric_value < b.metric_value ? 1 : -1))
        .map((x) => {
          if (this.dimensionMap) {
            const y = x;
            Object.keys(this.dimensionMap).forEach((key) => {
              if (String(x.dimension_value) === String(key)) {
                y.dimension_value = this.dimensionMap[key];
              }
            });
            return y;
          }
          return x;
        });
    },
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
    metrics: {
      type: Array,
    },
    title: {
      type: String,
    },
    dimensionMap: {
      type: Object,
    },
  },
  methods: {},
  mounted() {
    /* this.metrics.forEach((metric) => {
      console.log(this.title, metric.dimension_value, metric.metric_value);
    }); */
  },
});
</script>

<style scoped>

</style>

<template>
  <div class="stats-viewer">
    <div class="stats-viewer-timespan-buttons">
      <v-btn-toggle
        variant="outlined"
        divided
        v-model="data.timespan">

        <v-btn :to="{name: 'stats', query: {'timespan': 'total'}}" value="total">
          total
        </v-btn>

        <v-btn :to="{name: 'stats', query:{'timespan': '1_day'}}" value="1_day">
          1 day
        </v-btn>

        <v-btn :to="{name: 'stats', query: {'timespan': '1_week'}}" value="1_week">
          1 week
        </v-btn>

        <v-btn :to="{name: 'stats', query: {'timespan': '2_week'}}" value="2_week">
          2 weeks
        </v-btn>

        <v-btn :to="{name: 'stats', query: {'timespan': '1_month'}}" value="1_month">
          1 month
        </v-btn>

        <v-btn :to="{name: 'stats', query: {'timespan': '3_month'}}" value="3_month">
          3 months
        </v-btn>

        <v-btn :to="{name: 'stats', query: {'timespan': '6_month'}}" value="6_month">
          6 months
        </v-btn>

        <v-btn :to="{name: 'stats', query: {'timespan': '1_year'}}" value="1_year">
          1 year
        </v-btn>

      </v-btn-toggle>
    </div>

    <div class="stats-viewer-timespan-alert">
      <v-alert
        border
        closable
        color="error">
        This is an <b>internal only</b> dashboard, not for sharing outside Grazer employees --
        sensitive
        information is accessible within this portal.
      </v-alert>

      <v-alert
        border>
        Last updated: {{ this.data.metricsUpdatedAt }} <i>(updates daily) </i>
      </v-alert>
    </div>

    <div class="stats-viewer-inner">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5 col-sm-12">
            <div class="row">
              <div class="col-md-12">
                <div class="stats-container stats-viewer-users-map-container">
                  <div class="stats-container-header">
                    <h3> User location <i><small> ({{
                        this.humanizeTimespans(this.timespan)
                      }}) </small> </i></h3>
                  </div>
                  <div class="stats-container-content">
                    <v-btn-toggle
                      variant="outlined"
                      divided
                      mandatory
                      v-model="data.showG1Map">

                      <v-btn value="active_users">
                        active users
                      </v-btn>

                      <v-btn value="new_users">
                        new users
                      </v-btn>

                    </v-btn-toggle>
                    <div id="stats-viewer-users-map"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <StatsCountryCount v-if="this.data.loadedMetrics"
                                   :timespan="this.timespan"
                                   :metrics="this.data.metrics.GA_COUNTRY"/>
              </div>
              <div class="col-md-6">
                <StatsLanguageCount v-if="this.data.loadedMetrics"
                                    :timespan="this.timespan"
                                    :metrics="this.data.metrics.GA_LANGUAGE"/>
              </div>

              <div class="col-md-5">
                <StatsTransactions v-if="this.data.loadedMetrics"
                                   :timespan="this.timespan"
                                   :metrics="this.data.metrics.INTERNAL_TRANSACTIONS"
                                   :rewindMetrics="this.data.metrics.INTERNAL_REWINDS"/>
              </div>
              <div class="col-md-5">
                <StatsPlatform v-if="this.data.loadedMetrics"
                               :timespan="this.timespan"
                               :metrics="this.data.metrics.GA_PLATFORM"/>
              </div>
              <div class="col-md-5">
                <StatsAppVersion v-if="this.data.loadedMetrics"
                                 :timespan="this.timespan"
                                 :metrics="this.data.metrics.GA_APP_VERSION"/>
              </div>

            </div>
          </div>
          <div class="col-md-7 col-sm-12">
            <div class="row">
              <div class="col-md-3">
                <StatsUserRetention v-if="this.data.loadedMetrics"
                                    :timespan="this.timespan"
                                    :metrics="this.data.metrics.GA_NEW_VS_RETURNING"/>
              </div>
              <div class="col-md-9">
                <div class="stats-container stats-viewer-users-map-container">
                  <div class="stats-container-header">
                    <h3> User activity <i><small> ({{
                        this.humanizeTimespans(this.timespan)
                      }}) </small> </i></h3>
                  </div>
                  <div class="stats-container-content">
                    <v-btn-toggle
                      variant="outlined"
                      divided
                      mandatory
                      v-model="data.showG2">

                      <v-btn value="active_users">
                        active users
                      </v-btn>

                      <v-btn value="new_users">
                        new users
                      </v-btn>

                    </v-btn-toggle>
                    <div id="stats-viewer-users-date-graph"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <StatsUserCount :timespan="this.timespan"
                                :user-count-metric="this.data.userCountMetric"
                                :deleted-user-count-metric="this.data.deletedUserCountMetric"/>
              </div>
              <div class="col-md-4">
                <StatsUserAgeCount v-if="this.data.loadedMetrics"
                                   :timespan="this.timespan"
                                   :metrics="this.data.metrics.INTERNAL_AGE.ALL_USERS.TOTAL"/>
              </div>
              <div class="col-md-4">
                <StatsUserGender v-if="this.data.loadedMetrics"
                                 :timespan="this.timespan"
                                 :metrics="this.data.metrics.INTERNAL_GENDER.ALL_USERS.TOTAL"/>
              </div>
              <div class="col-md-4" v-if="this.data.metrics.INTERNAL_LOGIN_METHOD !== undefined">
                <StatsUserLoginMethod v-if="this.data.loadedMetrics"
                                 :timespan="this.timespan"
                                 :metrics="this.data.metrics.INTERNAL_LOGIN_METHOD.ALL_USERS.TOTAL"
                                 />
              </div>
              <div class="col-md-5">
                <StatsInteractionsCount v-if="this.data.loadedMetrics"
                                        :timespan="this.timespan"
                                        :matches-conversation-metrics=
                                          "this.data.metrics.INTERNAL_MATCHES_CONVERSATION_STARTED"
                                        :matches-count-metrics=
                                          "this.data.metrics.INTERNAL_MATCHES_COUNT"
                                        :matches-type-metrics=
                                          "this.data.metrics.INTERNAL_MATCHES_TYPE"
                                        :messages-metrics="this.data.metrics.INTERNAL_MESSAGES"
                                        :swipes-metrics="this.data.metrics.INTERNAL_SWIPES"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3" v-if="this.data.metrics.INTERNAL_DIET !== undefined">
                <StatsGeneric v-if="this.data.loadedMetrics"
                                 :timespan="this.timespan"
                                 :metrics="this.data.metrics.INTERNAL_DIET.ALL_USERS.TOTAL"
                                 title="Diet popularity"
                                 :dimensionMap="this.data.dietMap"
                                 />
              </div>
              <div class="col-md-3" v-if="this.data.metrics.INTERNAL_QUESTIONS !== undefined">
                <StatsGeneric v-if="this.data.loadedMetrics"
                                 :timespan="this.timespan"
                                 :metrics="this.data.metrics.INTERNAL_QUESTIONS.ALL_USERS.TOTAL"
                                 title="Question popularity"
                                 />
              </div>
              <div class="col-md-3" v-if="this.data.metrics.INTERNAL_BADGES !== undefined">
                <StatsGeneric v-if="this.data.loadedMetrics"
                                 :timespan="this.timespan"
                                 :metrics="this.data.metrics.INTERNAL_BADGES.ALL_USERS.TOTAL"
                                 title="Badges popularity"
                                 />
              </div>
              <div class="col-md-3" v-if="this.data.metrics.INTERNAL_HOBBIES !== undefined">
                <StatsGeneric v-if="this.data.loadedMetrics"
                                 :timespan="this.timespan"
                                 :metrics="this.data.metrics.INTERNAL_HOBBIES.ALL_USERS.TOTAL"
                                 title="Flavours popularity"
                                 />
              </div>
        </div>
        <div class="row">
              <div class="col-md-4">
                <StatsLookingFor v-if="this.data.loadedMetrics"
                                 user-type="ALL_USERS"
                                 :gender-type-metrics=
                                   "this.data.metrics.INTERNAL_LOOKING_FOR_GENDER_TYPE"
                                 :match-type-metrics=
                                   "this.data.metrics.INTERNAL_LOOKING_FOR_MATCH_TYPE"/>
              </div>
              <div class="col-md-4">
                <StatsLookingFor v-if="this.data.loadedMetrics"
                                 user-type="DATING_USERS"
                                 :gender-type-metrics=
                                   "this.data.metrics.INTERNAL_LOOKING_FOR_GENDER_TYPE"
                                 :match-type-metrics=
                                   "this.data.metrics.INTERNAL_LOOKING_FOR_MATCH_TYPE"/>
              </div>
              <div class="col-md-4">
                <StatsLookingFor v-if="this.data.loadedMetrics"
                                 user-type="FRIEND_USERS"
                                 :gender-type-metrics=
                                   "this.data.metrics.INTERNAL_LOOKING_FOR_GENDER_TYPE"
                                 :match-type-metrics=
                                   "this.data.metrics.INTERNAL_LOOKING_FOR_MATCH_TYPE"/>
            </div>
        </div>
        <div class="row">
          <div class="col-md-4" v-if="this.data.metrics.INTERNAL_CITY_DENSITY !== undefined">
                <StatsGeneric v-if="this.data.loadedMetrics"
                                 :timespan="this.timespan"
                                 :metrics="this.data.metrics.INTERNAL_CITY_DENSITY.ALL_USERS.TOTAL"
                                 title="City density (legacy)"
                                 />
              </div>
              <div class="col-md-4" v-if="this.data.metrics.INTERNAL_CITY_DENSITY !== undefined">
                <StatsGeneric v-if="this.data.loadedMetrics"
                                 timespan="Since launch"
                                 :metrics="this.data.metrics.INTERNAL_CITY_DENSITY.ALL_USERS.LAUNCH"
                                 title="City density"
                                 />
              </div>
              <div class="col-md-4" v-if="this.data.metrics.INTERNAL_STATE_DENSITY !== undefined">
                <!-- eslint-disable max-len -->
                <StatsGeneric v-if="this.data.loadedMetrics"
                                 timespan="Since launch"
                                 :metrics="this.data.metrics.INTERNAL_STATE_DENSITY.ALL_USERS.LAUNCH"
                                 title="US State density"
                                 />
                <!-- eslint-enable max-len -->
              </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// GA_APP_VERSION:Object
// GA_COUNTRY:Object
// GA_DATE:Object
// GA_LANGUAGE:Object
// GA_NEW_VS_RETURNING:Object
// GA_PLATFORM:Object
// GA_REGION:Object
// INTERNAL_AGE:Object
// INTERNAL_DELETED_USER_COUNT:Object
// INTERNAL_DIET:Object
// INTERNAL_GENDER:Object
// INTERNAL_LOOKING_FOR_GENDER_TYPE:Object
// ALL_USERS:Object
// INTERNAL_LOOKING_FOR_MATCH_TYPE:Object
// INTERNAL_MATCHES_CONVERSATION_STARTED:Object
// COUNT:Object
// INTERNAL_MATCHES_COUNT:Object
// INTERNAL_MATCHES_TYPE:Object
// INTERNAL_MESSAGES:Object
// INTERNAL_SWIPES:Object
// INTERNAL_TRANSACTIONS:Object
// INTERNAL_USER_COUNT:Object

import { defineComponent, onMounted } from 'vue';
import moment from 'moment';
import StatsUserCount from '@/components/StatsUserCount.vue';
import StatsUserAgeCount from '@/components/StatsUserAgeCount.vue';
import StatsUserGender from '@/components/StatsUserGender.vue';
import StatsUserLoginMethod from '@/components/StatsUserLoginMethod.vue';
import StatsCountryCount from '@/components/StatsCountryCount.vue';
import StatsLanguageCount from '@/components/StatsLanguageCount.vue';
import StatsInteractionsCount from '@/components/StatsInteractionsCount.vue';
import StatsLookingFor from '@/components/StatsLookingFor.vue';
import StatsMixin from '@/mixins/StatsMixin.vue';
import StatsTransactions from '@/components/StatsTransactions.vue';
import StatsUserRetention from '@/components/StatsUserRetention.vue';
import StatsAppVersion from '@/components/StatsAppVersion.vue';
import StatsPlatform from '@/components/StatsPlatform.vue';
import StatsGeneric from '@/components/StatsGeneric.vue';

export default defineComponent({
  name: 'StatsViewer',
  data() {
    return {
      data: {
        loadedMetrics: false,
        jwt: {},
        rawMetrics: {},
        metrics: {},
        parsedDateMetrics: [],
        metricsUpdatedAt: null,
        timespan: this.timespan,
        showG1Map: 'active_users',
        showG2: 'active_users',
        userCountMetric: {},
        deletedUserCountMetric: {},
        genderMetric: [],
        ageMetric: [],
        dietMap: {
          1: 'vegan',
          2: 'vegetarian',
          3: 'plant-based',
        },
      },
    };
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
  },
  computed: {
    normalisedTimespan() {
      return this.normaliseTimespan(this.timespan);
      // switch (this.timespan) {
      //   case '1_day':
      //     return 'ONE_DAY';
      //   case '1_week':
      //     return 'ONE_WEEK';
      //   case '2_week':
      //     return 'TWO_WEEKS';
      //   case '1_month':
      //     return 'ONE_MONTH';
      //   case '3_month':
      //     return 'THREE_MONTHS';
      //   case '6_month':
      //     return 'SIX_MONTHS';
      //   case '1_year':
      //     return 'ONE_YEAR';
      //   case 'total':
      //   default:
      //     return 'TOTAL';
      // }
    },
    normalisedShowG1Map() {
      return this.data.showG1Map === 'active_users' ? 'ACTIVE_USERS' : 'NEW_USERS';
    },
    normalisedShowG2() {
      return this.data.showG2 === 'active_users' ? 'ACTIVE_USERS' : 'NEW_USERS';
    },
    normalisedTimespanToDays() {
      return this.normaliseTimespanToDays(this.timespan);
      // switch (this.timespan) {
      //   case '1_day':
      //     return 1;
      //   case '1_week':
      //     return 7;
      //   case '2_week':
      //     return 14;
      //   case '1_month':
      //     return 31;
      //   case '3_month':
      //     return 31 * 3;
      //   case '6_month':
      //     return 31 * 6;
      //   case '1_year':
      //     return 365;
      //   case 'total':
      //   default:
      //     return null;
      // }
    },
  },
  setup() {
    onMounted(() => {
      console.log('mounted in the composition api');
    });
  },
  methods: {
    async getJWT() {
      const res = await fetch(`${process.env.VUE_APP_API_URL}/v1/auth-portal/get-token?auth-portal-key=VQ3hLKhezDuFvFBBXpyTytxx6B4E2P9vy9RAAr2fv66SsVXwVBM4JgLZYJmK2MK8`);
      const data = await res.json();
      return data.data.auth.data.token;
    },
    async getStats() {
      console.log('getStats()');
      console.log('getJWT()');
      const jwt = await this.getJWT();
      console.log(`\t -> ${jwt}`);
      console.log('fetching stats');

      const res = await fetch(`${process.env.VUE_APP_API_URL}/v1/portal/stats`, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });
      const data = await res.json();
      this.data.rawMetrics = data.data.portal_metrics.data;
      console.log(`\t -> stats fetched: ${this.data.rawMetrics.length}`);
      this.data.metricsUpdatedAt = moment(this.data.rawMetrics[0].created_at);
      data.data.portal_metrics.data.forEach((metric) => {
        if (!(metric.dimension in this.data.metrics)) {
          this.data.metrics[metric.dimension] = {};
        }

        if (!(metric.metric in this.data.metrics[metric.dimension])) {
          this.data.metrics[metric.dimension][metric.metric] = {};
        }

        if (!(metric.timespan in this.data.metrics[metric.dimension][metric.metric])) {
          this.data.metrics[metric.dimension][metric.metric][metric.timespan] = [];
        }

        const newMetric = metric;
        if (metric.dimension === 'GA_DATE') {
          newMetric.dimension_value_date_parsed = moment(metric.dimension_value, 'YYYYMMDD');
          newMetric.dimension_value_date_sortable = newMetric.dimension_value_date_parsed.unix();
          newMetric.dimension_value_date_human = newMetric.dimension_value_date_parsed.format('YYYY-MM-DD');
          newMetric.dimension_value_date_js = newMetric.dimension_value_date_parsed.toDate();
        }

        if (metric.dimension === 'INTERNAL_USER_COUNT') {
          if (this.data.userCountMetric[metric.timespan] === undefined) {
            this.data.userCountMetric[metric.timespan] = [];
          }
          this.data.userCountMetric[metric.timespan].push(metric);
        }

        if (metric.dimension === 'INTERNAL_DELETED_USER_COUNT') {
          this.data.deletedUserCountMetric[metric.timespan] = metric;
        }

        this.data.metrics[metric.dimension][metric.metric][metric.timespan].push(newMetric);
      });

      this.data.metrics.GA_DATE.ACTIVE_USERS.TOTAL.sort(
        (a, b) => ((a.dimension_value_date_sortable > b.dimension_value_date_sortable) ? -1 : 1),
      );
      this.data.metrics.GA_DATE.NEW_USERS.TOTAL.sort(
        (a, b) => ((a.dimension_value_date_sortable > b.dimension_value_date_sortable) ? -1 : 1),
      );

      this.data.loadedMetrics = true;
      this.loadUsersMap();
      this.loadUsersDateChart();
    },
    loadUsersMap() {
      window.google.charts.load('current', {
        packages: ['geochart'],
        mapsApiKey: 'AIzaSyB5SAYfK3gZo1ovfe8bLAzAYWvhCkT4z98',
      });
      window.google.charts.setOnLoadCallback(() => {
        const arr = [['Country', 'Users']];

        console.log(this.normalisedShowG1Map);
        this.data.metrics.GA_COUNTRY[this.normalisedShowG1Map][this.normalisedTimespan]
          .forEach((metric) => {
            arr.push([metric.dimension_value, metric.metric_value]);
          });

        const data = window.google.visualization.arrayToDataTable(arr);

        const chart = new window.google.visualization.GeoChart(
          document.getElementById('stats-viewer-users-map'),
        );
        chart.draw(data, {
          legend: 'none',
        });
      });
    },
    loadUsersDateChart() {
      window.google.charts.load('current', { packages: ['corechart'] });
      window.google.charts.setOnLoadCallback(() => {
        const days = this.normalisedTimespanToDays;
        const data = new window.google.visualization.DataTable();
        data.addColumn('date', 'Day');
        data.addColumn('number', 'Users');

        const rows = [];
        if (days === null) {
          this.data.metrics.GA_DATE[this.normalisedShowG2].TOTAL.forEach((metric) => {
            rows.push([metric.dimension_value_date_js, metric.metric_value]);
          });
        } else {
          const metrics = this.data.metrics.GA_DATE[this.normalisedShowG2].TOTAL;
          for (let i = 0; i <= days; i += 1) {
            rows.push([metrics[i].dimension_value_date_js, metrics[i].metric_value]);
          }
        }

        data.addRows(rows);

        // const data = window.google.visualization.arrayToDataTable(arr);
        const chart = new window.google.visualization.LineChart(
          document.getElementById('stats-viewer-users-date-graph'),
        );
        chart.draw(data, {
          legend: 'none',
          hAxis: {
            format: 'M/d/yy',
            // gridlines: { count: 30 },
          },
          vAxis: {
            gridlines: { color: 'none' },
            minValue: 0,
          },
        });
      });
    },
  },
  mounted() {
    console.log('mounted in the post composition api');
    this.getStats();
  },
  components: {
    StatsTransactions,
    StatsCountryCount,
    StatsLanguageCount,
    StatsUserCount,
    StatsUserAgeCount,
    StatsUserGender,
    StatsUserLoginMethod,
    StatsInteractionsCount,
    StatsLookingFor,
    StatsUserRetention,
    StatsAppVersion,
    StatsPlatform,
    StatsGeneric,
  },
  mixins: [
    StatsMixin,
  ],
  watch: {
    timespan(newVal) {
      this.data.timespan = newVal;
      this.loadUsersMap();
      this.loadUsersDateChart();
    },
    'data.showG1Map': function () {
      this.loadUsersMap();
    },
    'data.showG2': function () {
      this.loadUsersDateChart();
    },
  },
});
</script>

<style lang="scss">
.stats-viewer {
  padding: 30px 0;
}

.stats-viewer-inner {
  padding: 30px 0;
}

.v-btn-group {
  background: #fff;

  .v-btn {
    border-color: #BDBDBD;

    &:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}

.stats-container {
  background: #fff;
  //padding: 15px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #bdbdbd;
  margin-bottom: 15px;

  .stats-container-header {
    width: 100%;
    padding: 15px;
    background: #FAFAFA;
    border-bottom: 1px solid #EEEEEE;

    h3 {
      margin: 0;
      padding: 0;
    }
  }

  .stats-container-content {
    padding: 15px;
  }

  .v-btn-group {
    margin-bottom: 15px;
  }
}

.stats-viewer-timespan-buttons {
  margin-bottom: 15px;
}

.stats-viewer-timespan-alert {
}

.v-alert {
  margin-bottom: 15px;
}
</style>

<script>
export default {
  name: 'StatsMixin',
  methods: {
    normaliseTimespan(timespan) {
      switch (timespan) {
        case '1_day':
          return 'ONE_DAY';
        case '2_day':
          return 'TWO_DAYS';
        case '3_day':
          return 'THREE_DAYS';
        case '4_day':
          return 'FOUR_DAYS';
        case '5_day':
          return 'FIVE_DAYS';
        case '6_day':
          return 'SIX_DAYS';
        case '1_week':
          return 'ONE_WEEK';
        case '2_week':
          return 'TWO_WEEKS';
        case '1_month':
          return 'ONE_MONTH';
        case '3_month':
          return 'THREE_MONTHS';
        case '6_month':
          return 'SIX_MONTHS';
        case '1_year':
          return 'ONE_YEAR';
        case 'total':
        default:
          return 'TOTAL';
      }
    },
    normaliseTimespanToDays(timespan) {
      switch (timespan) {
        case '1_day':
          return 1;
        case '2_days':
          return 2;
        case '3_days':
          return 3;
        case '4_days':
          return 4;
        case '5_days':
          return 5;
        case '6_days':
          return 6;
        case '1_week':
          return 7;
        case '2_week':
          return 14;
        case '1_month':
          return 31;
        case '3_month':
          return 31 * 3;
        case '6_month':
          return 31 * 6;
        case '1_year':
          return 365;
        case 'total':
        default:
          return null;
      }
    },
    humanizeAPITimespans(timespan) {
      switch (timespan) {
        case 'ONE_DAY':
          return 'Yesterday';
        case 'TWO_DAYS':
          return 'Two days ago';
        case 'THREE_DAYS':
          return 'Three days ago';
        case 'FOUR_DAYS':
          return 'Four days ago';
        case 'FIVE_DAYS':
          return 'Five days ago';
        case 'SIX_DAYS':
          return 'Six days ago';
        case 'ONE_WEEK':
          return 'One week';
        case 'TWO_WEEKS':
          return 'Two weeks';
        case 'ONE_MONTH':
          return 'One month';
        case 'THREE_MONTHS':
          return 'Three months';
        case 'SIX_MONTHS':
          return 'Six months';
        case 'ONE_YEAR':
          return 'One year';
        case 'TOTAL':
        default:
          return 'Total';
      }
    },
    humanizeTimespans(timespan) {
      switch (timespan) {
        case '1_day':
          return 'Yesterday';
        case '2_day':
          return 'Two days ago';
        case '3_day':
          return 'Three days ago';
        case '4_day':
          return 'Four days ago';
        case '5_day':
          return 'Five days ago';
        case '6_day':
          return 'Six days ago';
        case '1_week':
          return 'One week';
        case '2_week':
          return 'Two weeks';
        case '1_month':
          return 'One month';
        case '3_month':
          return 'Three months';
        case '6_month':
          return 'Six months';
        case '1_year':
          return 'One year';
        case 'total':
        default:
          return 'Total';
      }
    },
  },
};
</script>

<!--
 -- message count
 -- swipe count
 -- matches count
 -- swipe / matches percent
 -- avg messages per match
 -- avg swipes per user
 -->
<template>
  <div class="stats-container stats-viewer-users-overview">
    <div class="stats-container-header">
      <h3> User Interactions <i><small> ({{ this.humanizeTimespans(this.timespan) }}) </small> </i>
      </h3>
    </div>
    <div class="stats-container-content">
      <h5> Matches </h5>
      <v-table>
        <thead>
        <tr>
          <th class="text-left">
            Metric
          </th>
          <th class="text-left">
            Count
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td> Conversation started matches</td>
          <td> {{ this.conversationStartedMatches }}</td>
        </tr>
        <tr>
          <td> Conversation not started matches</td>
          <td> {{ this.conversationNotStartedMatches }}</td>
        </tr>
        <tr>
          <td> Friendship matches</td>
          <td> {{ this.friendshipMatches }}</td>
        </tr>
        <tr>
          <td> Relationship matches</td>
          <td> {{ this.relationshipMatches }}</td>
        </tr>
        <tr>
          <td> Matches</td>
          <td> {{ this.totalMatches }}</td>
        </tr>
        </tbody>
      </v-table>
      <v-divider></v-divider>
      <h5> Swipes </h5>
      <v-table>
        <thead>
        <tr>
          <th class="text-left">
            Metric
          </th>
          <th class="text-left">
            Count
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td> Count</td>
          <td> {{ this.swipesCount }}</td>
        </tr>
        <tr>
          <td> Avg swipes per match</td>
          <td> {{ this.swipesPerMatch }} (avg + estimation)</td>
        </tr>
        <tr>
          <td> Match chance on swipe</td>
          <td> {{ this.swipesMatchChance }}% (avg + estimation)</td>
        </tr>
        </tbody>
      </v-table>
      <v-divider></v-divider>
      <h5> Messages </h5>
      <v-table>
        <thead>
        <tr>
          <th class="text-left">
            Metric
          </th>
          <th class="text-left">
            Count
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td> Count</td>
          <td> {{ this.messagesCount }}</td>
        </tr>
        <tr>
          <td> Avg messages per match</td>
          <td> {{ this.messagesPerMatch }} </td>
        </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StatsMixin from '@/mixins/StatsMixin.vue';

export default defineComponent({
  name: 'StatsInteractionCount',
  data() {
    return {
      data: {},
    };
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
    matchesConversationMetrics: {
      type: Object,
    },
    matchesCountMetrics: {
      type: Object,
    },
    matchesTypeMetrics: {
      type: Object,
    },
    messagesMetrics: {
      type: Object,
    },
    swipesMetrics: {
      type: Object,
    },
  },
  methods: {},
  computed: {
    conversationStartedMatches() {
      let count = 0;
      if (
        this.matchesConversationMetrics.COUNT[this.normaliseTimespan(this.timespan)] === undefined
      ) {
        return count;
      }
      this.matchesConversationMetrics.COUNT[this.normaliseTimespan(this.timespan)]
        .forEach((metric) => {
          if (parseInt(metric.dimension_value, 10) === 0) {
            count = metric.metric_value;
          }
        });
      return count;
    },
    conversationNotStartedMatches() {
      let count = 0;
      if (
        this.matchesConversationMetrics.COUNT[this.normaliseTimespan(this.timespan)] === undefined
      ) {
        return count;
      }
      this.matchesConversationMetrics.COUNT[this.normaliseTimespan(this.timespan)]
        .forEach((metric) => {
          if (parseInt(metric.dimension_value, 10) === 1) {
            count = metric.metric_value;
          }
        });
      return count;
    },
    relationshipMatches() {
      let count = 0;
      if (
        this.matchesConversationMetrics.COUNT[this.normaliseTimespan(this.timespan)] === undefined
      ) {
        return count;
      }
      this.matchesTypeMetrics.COUNT[this.normaliseTimespan(this.timespan)]
        .forEach((metric) => {
          if (parseInt(metric.dimension_value, 10) === 1) {
            count = metric.metric_value;
          }
        });
      return count;
    },
    friendshipMatches() {
      let count = 0;
      if (
        this.matchesConversationMetrics.COUNT[this.normaliseTimespan(this.timespan)] === undefined
      ) {
        return count;
      }
      this.matchesTypeMetrics.COUNT[this.normaliseTimespan(this.timespan)]
        .forEach((metric) => {
          if (parseInt(metric.dimension_value, 10) === 0) {
            count = metric.metric_value;
          }
        });
      return count;
    },
    totalMatches() {
      return this.matchesCountMetrics
        .COUNT[this.normaliseTimespan(this.timespan)][0]
        .metric_value;
    },
    swipesCount() {
      return this.swipesMetrics
        .COUNT[this.normaliseTimespan(this.timespan)][0]
        .metric_value;
    },
    swipesPerMatch() {
      const swipes = this.swipesCount;
      const matches = this.totalMatches;

      return Math.round((swipes / 2) / (matches * 2));
    },
    swipesMatchChance() {
      const swipes = this.swipesCount;
      const matches = this.totalMatches;

      return Math.round(((matches * 2) / (swipes / 2)) * 100);
    },
    messagesCount() {
      return this.messagesMetrics
        .COUNT[this.normaliseTimespan(this.timespan)][0]
        .metric_value;
    },
    messagesPerMatch() {
      const matches = this.totalMatches;
      const messages = this.messagesCount;

      return Math.round(messages / matches);
    },
    popularCountries() {
      // // -- message count
      // // -- swipe count
      // // -- matches count
      // // -- swipe / matches percent
      // // -- avg messages per match
      // // -- avg swipes per user
      // const showing = this.data.show === 'active_users' ? 'ACTIVE_USERS' : 'NEW_USERS';
      // const buckets = [];
      //
      // console.log(this.timespan);
      // console.log(this.normaliseTimespan(this.timespan));
      // this.metrics[showing][this.normaliseTimespan(this.timespan)].forEach((metric) => {
      //   buckets.push(metric);
      // });
      //
      // buckets.sort(
      //   (a, b) => ((a.metric_value > b.metric_value) ? -1 : 1),
      // );
      //
      // const splicedBuckets = buckets.slice(0, 9);
      // splicedBuckets[9] = {
      //   dimension_value: 'Other',
      //   metric_value: 0,
      // };
      //
      // buckets.slice(9)
      //   .forEach((metric) => {
      //     splicedBuckets[9].metric_value += metric.metric_value;
      //   });
      //
      // return splicedBuckets;

      return 'hello';
    },
  },
  mixins: [
    StatsMixin,
  ],
  mounted() {
    // this.metrics.forEach((metric) => {
    //   const gender = parseInt(metric.dimension_value, 10);
    //   const count = parseInt(metric.metric_value, 10);
    //
    //   if (gender === 1) {
    //     this.data.metricBuckets.male.count += count;
    //   } else if (gender === 2) {
    //     this.data.metricBuckets.female.count += count;
    //   } else if (gender === 3) {
    //     this.data.metricBuckets.x.count += count;
    //   }
    // });
  },
  watch: {
    show(newVal) {
      console.log(newVal);
    },
    timespan(newVal) {
      console.log(newVal);
    },
  },
});
</script>

<style scoped>

</style>

<template>
  <v-app>
    <v-app-bar
      color="dark">
      <v-app-bar-nav-icon color="white" variant="text"
                          @click.stop="this.data.drawer = !this.data.drawer"></v-app-bar-nav-icon>
      <v-app-bar-title><a href="/">Grazer dashboard</a></v-app-bar-title>
      <v-spacer></v-spacer>

    </v-app-bar>
    <v-navigation-drawer
      v-model="this.data.drawer"
    >
      <v-list nav>
        <v-list-item :to="{name: 'stats'}">
          <v-list-item-content>
            <v-list-item-title>
              Stats
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{name: 'users'}">
          <v-list-item-content>
            <v-list-item-title>
              Users
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{name: 'blocked-users'}">
          <v-list-item-content>
            <v-list-item-title>
              Blocked Users
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'AppLayout',
  data() {
    return {
      data: {
        drawer: false,
      },
    };
  },
};
</script>

<style lang="scss">
.v-toolbar-title {
  color: #fff;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }

}
</style>

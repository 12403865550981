<template>
  <div class="stats-container stats-viewer-users-overview">
    <div class="stats-container-header">
      <h3> User retention <i><small> ({{
          this.humanizeTimespans(this.timespan)
        }}) </small> </i>
      </h3>
    </div>
    <div class="stats-container-content">
      <v-table>
        <thead>
        <tr>
          <th class="text-left">
            User type
          </th>
          <th class="text-left">
            Count
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item) in this.newVsReturning" :key="item.dimension_value">
          <td> {{ item.dimension_value }}</td>
          <td> {{ item.metric_value }}</td>
        </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StatsMixin from '@/mixins/StatsMixin.vue';

export default defineComponent({
  name: 'StatsRetention',
  data() {
    return {
      data: {
        metricBuckets: [],
      },
    };
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
    metrics: {
      type: Object,
    },
  },
  methods: {},
  computed: {
    newVsReturning() {
      const buckets = [];

      this.metrics.ACTIVE_USERS[this.normaliseTimespan(this.timespan)].forEach((metric) => {
        buckets.push(metric);
      });

      buckets.sort(
        (a, b) => ((a.metric_value > b.metric_value) ? -1 : 1),
      );

      return buckets;
    },
  },
  mixins: [
    StatsMixin,
  ],
  mounted() {
  },
  watch: {
    show(newVal) {
      console.log(newVal);
    },
    timespan(newVal) {
      console.log(newVal);
    },
  },
});
</script>

<style scoped>

</style>

<template>
  <div class="stats-container stats-viewer-users-overview">
    <div class="stats-container-header">
      <h3> Popular Languages <i><small> ({{ this.humanizeTimespans(this.timespan) }}) </small> </i>
      </h3>
    </div>
    <div class="stats-container-content">
      <i> This is languages set by the phone, not the language set within the app </i>
      <v-table>
        <thead>
        <tr>
          <th class="text-left">
            Gender
          </th>
          <th class="text-left">
            Count
          </th>
          <th class="text-left">
            %
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item) in this.popularLanguages" :key="item.dimension_value">
          <td> {{ item.dimension_value }}</td>
          <td> {{ item.metric_value }}</td>
          <td>{{ Math.round(((item.metric_value / this.totalCount)*10000))/100 }}%</td>
        </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StatsMixin from '@/mixins/StatsMixin.vue';

export default defineComponent({
  name: 'StatsCountryCount',
  data() {
    return {
      data: {
        metricBuckets: [],
        totalCount: 0,
      },
    };
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
    metrics: {
      type: Object,
    },
  },
  methods: {},
  computed: {
    totalCount() {
      let totalCount = 0;
      this.metrics.ACTIVE_USERS[this.normaliseTimespan(this.timespan)].forEach((metric) => {
        totalCount += metric.metric_value;
      });
      return totalCount;
    },
    popularLanguages() {
      const buckets = [];

      this.metrics.ACTIVE_USERS[this.normaliseTimespan(this.timespan)].forEach((metric) => {
        buckets.push(metric);
      });

      buckets.sort(
        (a, b) => ((a.metric_value > b.metric_value) ? -1 : 1),
      );

      const splicedBuckets = buckets.slice(0, 9);
      splicedBuckets[9] = {
        dimension_value: 'Other',
        metric_value: 0,
      };

      buckets.slice(9)
        .forEach((metric) => {
          splicedBuckets[9].metric_value += metric.metric_value;
        });

      return splicedBuckets;
    },
  },
  mixins: [
    StatsMixin,
  ],
  mounted() {
    // this.metrics.forEach((metric) => {
    //   const gender = parseInt(metric.dimension_value, 10);
    //   const count = parseInt(metric.metric_value, 10);
    //
    //   if (gender === 1) {
    //     this.data.metricBuckets.male.count += count;
    //   } else if (gender === 2) {
    //     this.data.metricBuckets.female.count += count;
    //   } else if (gender === 3) {
    //     this.data.metricBuckets.x.count += count;
    //   }
    // });
  },
  watch: {
    show(newVal) {
      console.log(newVal);
    },
    timespan(newVal) {
      console.log(newVal);
    },
  },
});
</script>

<style scoped>

</style>

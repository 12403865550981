<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      data: {
        drawer: false,
      },
    };
  },
};
</script>

<style>
.v-toolbar-title {
  color: #fff;
}
</style>

<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'DefaultLayout',
  data() {
    return {
      data: {
        drawer: false,
      },
    };
  },
};
</script>

<style>
.v-toolbar-title {
  color: #fff;
}
</style>

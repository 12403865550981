<template>
  <div class="stats-view">
    <h1>Stats</h1>
    <v-divider></v-divider>
    <StatsViewer :timespan="timespan"/>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue';

// Components
import StatsViewer from '../components/StatsViewer.vue';

export default defineComponent({
  name: 'StatsView',
  data() {
    return {
      data: {
        timespan: this.timespan,
      },
    };
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
  },
  setup() {
    onMounted(() => {
      console.log('StatsView::composition_api::mounted');
    });
  },
  methods: {},
  mounted() {
    console.log('StatsView::mounted');
  },
  components: {
    StatsViewer,
  },
});
</script>

import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '@/layout/DefaultLayout.vue';
import AppLayout from '@/layout/AppLayout.vue';
import StatsView from '../views/StatsView.vue';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    redirect: { name: 'stats' },
    component: AppLayout,
    meta: {
      title: 'Grazer Dashboard',
    },
    children: [
      {
        name: 'stats',
        path: '/stats',
        meta: {
          title: 'Stats | Grazer Dashboard',
        },
        component: StatsView,
        props: (route) => ({ timespan: route.query.timespan }),
      },
      {
        path: '/blocked-users',
        meta: {
          title: 'Blocked Users | Grazer Dashboard',
        },
        name: 'blocked-users',
        component: () => import(/* webpackChunkName: "blocked-users" */ '../views/BlockedUsersView.vue'),
      },
      {
        path: '/users',
        meta: {
          title: 'Users | Grazer Dashboard',
        },
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: DefaultLayout,
    meta: {
      title: 'Login | Grazer Dashboard',
    },
    children: [
      {
        path: '',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
      },
    ],
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  console.log(to, from, next);
  const token = localStorage.getItem('auth_portal_key.token');
  const authd = !!token;

  console.log(`foundtoken: ${token}`);

  if (to.name === 'login' && !authd) {
    next();
  }

  if (to.name === 'login' && authd) {
    next('/');
  }

  if (authd) {
    next(); // allow to enter route
  }

  next('/login');
});

export default router;

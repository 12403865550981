<template>
  <div class="stats-container stats-viewer-users-overview">
    <div class="stats-container-header">
      <h3>
        User Preferences
        <i>
          {{ this.userType.split('_')[0] }}
          <small> ({{ this.humanizeTimespans(this.timespan) }}) </small>
        </i>
      </h3>
    </div>
    <div class="stats-container-content">
      <h5> Gender preferences </h5>
      <v-table>
        <thead>
        <tr>
          <th class="text-left">
            Looking for gender desc
          </th>
          <th class="text-left">
            Count
          </th>
          <th class="text-left">
            %
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, key) in this.genderPreferences" :key="key">
          <td> {{ `${item.gender} > ${item.lookingFor}` }}</td>
          <td> {{ item.count }}</td>
          <td>{{ Math.round(((item.count / this.totalCount)*10000))/100 }}%</td>
        </tr>
        </tbody>
      </v-table>
      <v-divider></v-divider>
      <h5> Gender preferences summary </h5>
      <v-table>
        <thead>
        <tr>
          <th class="text-left">
            Looking for gender desc
          </th>
          <th class="text-left">
            Count
          </th>
          <th class="text-left">
            %
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td> Same gender preference </td>
          <td> {{ this.sameSexPreference }} </td>
          <td>{{ Math.round(((this.sameSexPreference / this.totalCount)*10000))/100 }}%</td>
        </tr>
        <tr>
          <td> Heterosexual preference </td>
          <td> {{ this.hetroSexPreference }} </td>
          <td>{{ Math.round(((this.hetroSexPreference / this.totalCount)*10000))/100 }}%</td>
        </tr>
        <tr>
          <td> Multi gender preference </td>
          <td> {{ this.multiPreference }} </td>
          <td>{{ Math.round(((this.multiPreference / this.totalCount)*10000))/100 }}%</td>
        </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StatsMixin from '@/mixins/StatsMixin.vue';

export default defineComponent({
  name: 'StatsLookingFor',
  data() {
    return {
      data: {
        totalCount: 0,
      },
    };
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
    userType: {
      type: String,
      default: 'ALL_USERS',
    },
    metrics: {
      type: Object,
    },
    genderTypeMetrics: {
      type: Object,
    },
    matchTypeMetrics: {
      type: Object,
    },
  },
  methods: {},
  computed: {
    totalCount() {
      const buckets = this.genderPreferences;
      return buckets.reduce((sum, x) => sum + x.count, 0);
    },
    genderPreferences() {
      const genderMap = {
        m: 1,
        f: 2,
        x: 3,
      };
      const lookingForBuckets = [];
      this.genderTypeMetrics[this.userType].TOTAL.forEach((metric) => {
        let readable = metric.dimension_value;
        Object.keys(genderMap).forEach((key) => {
          readable = readable.replaceAll(genderMap[key], key);
        });
        const gender = readable.split('=')[0];
        const lookingFor = readable.split('=')[1];

        if (Object.keys(genderMap).includes(gender)) {
          lookingForBuckets.push({
            genderId: metric.dimension_value.split('=')[0],
            gender,
            lookingFor,
            count: metric.metric_value,
          });
        }
      });
      return lookingForBuckets.sort((a, b) => (a.count < b.count ? 1 : -1));
    },
    sameSexPreference() {
      const buckets = this.genderPreferences;
      return buckets.reduce((sum, x) => {
        if (x.gender === x.lookingFor) {
          return sum + x.count;
        }
        return sum;
      }, 0);
    },
    hetroSexPreference() {
      const buckets = this.genderPreferences;
      return buckets.reduce((sum, x) => {
        if (x.lookingFor.length === 1 && x.gender !== x.lookingFor) {
          return sum + x.count;
        }
        return sum;
      }, 0);
    },
    multiPreference() {
      const buckets = this.genderPreferences;
      return buckets.reduce((sum, x) => {
        if (x.lookingFor.length > 1) {
          return sum + x.count;
        }
        return sum;
      }, 0);
    },
  },
  mixins: [
    StatsMixin,
  ],
  mounted() {
    // this.metrics.forEach((metric) => {
    //   const gender = parseInt(metric.dimension_value, 10);
    //   const count = parseInt(metric.metric_value, 10);
    //
    //   if (gender === 1) {
    //     this.data.metricBuckets.male.count += count;
    //   } else if (gender === 2) {
    //     this.data.metricBuckets.female.count += count;
    //   } else if (gender === 3) {
    //     this.data.metricBuckets.x.count += count;
    //   }
    // });
  },
  watch: {
    show(newVal) {
      console.log(newVal);
    },
    timespan(newVal) {
      console.log(newVal);
    },
  },
});
</script>

<style scoped>

</style>

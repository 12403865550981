<template>
  <div class="stats-container stats-viewer-users-overview">
    <div class="stats-container-header">
      <h3> User login method <i><small> (total) </small></i>  </h3>
    </div>
    <div class="stats-container-content">
      <v-table v-if="this.metrics.length > 0">
        <thead>
        <tr>
          <th class="text-left">
            Login method
          </th>
          <th class="text-left">
            Count
          </th>
          <th class="text-left">
            %
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(data, index) in this.metrics" :key="index">
          <td>{{ data.dimension_value }}</td>
          <td>{{ data.metric_value }}</td>
          <td>{{ Math.round(((data.metric_value / this.data.totalCount)*10000))/100 }}%</td>
        </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatsUserLoginMethod',
  data() {
    return {
      data: {
        totalCount: 0,
      },
    };
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
    metrics: {
      type: Array,
    },
  },
  methods: {},
  mounted() {
    this.data.totalCount = Object.values(this.metrics)
      .reduce((sum, x) => sum + x.metric_value, 0);
  },
});
</script>

<style scoped>

</style>

<template>
  <div class="stats-container stats-viewer-users-overview">
    <div class="stats-container-header">
      <h3> User count <i><small> (total) </small></i></h3>
    </div>
    <div class="stats-container-content">
      <v-table v-if="Object.keys(this.userCountMetric).length > 0">
        <thead>
        <tr>
          <th class="text-left">
            Timespan
          </th>
          <th class="text-left">
            Registered
          </th>
          <th class="text-left">
            Deleted
          </th>
          <th class="text-left">
            Diff
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(val, key) in this.userMetrics" :key="key">
          <td>{{this.humanizeAPITimespans(key)}}</td>
          <td>+{{ val.new }}</td>
          <td>-{{ val.deleted }}</td>
          <td>{{ val.diff }}</td>
        </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StatsMixin from '@/mixins/StatsMixin.vue';

export default defineComponent({
  name: 'StatsUserCount',
  data() {
    return {
      data: {
        userCount: {},
      },
    };
  },
  mixins: [
    StatsMixin,
  ],
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
    userCountMetric: {
      type: Object,
    },
    deletedUserCountMetric: {
      type: Object,
    },
  },
  computed: {
    userMetrics() {
      let registered = 0;
      let deleted = 0;

      Object.keys(this.userCountMetric).forEach((key) => {
        let newUsers = this.userCountMetric[key][0].metric_value;
        if (key.endsWith('_DAYS')) {
          newUsers -= registered;
          if (newUsers < 0) {
            newUsers *= -1;
          }
        }
        console.log(newUsers);
        registered = this.userCountMetric[key][0].metric_value;
        let deletedUsers = this.deletedUserCountMetric[key].metric_value;
        if (key.endsWith('_DAYS')) {
          deletedUsers -= deleted;
          if (deletedUsers < 0) {
            deletedUsers *= -1;
          }
        }
        deleted = this.deletedUserCountMetric[key].metric_value;
        this.data.userCount[key] = {
          new: newUsers,
          deleted: deletedUsers,
          diff: this.netDiff(
            newUsers,
            deletedUsers,
          ),
        };
      });

      return this.data.userCount;
    },
  },
  methods: {
    netDiff(newValue, oldValue) {
      const value = newValue - oldValue;
      if (value === 0) {
        return 0;
      }
      return value > 0 ? `+${value}` : `${value}`;
    },
  },
});
</script>

<style scoped>

</style>

<template>
  <div class="stats-container stats-viewer-users-overview">
    <div class="stats-container-header">
      <h3> Transactions / App purchases <i><small> ({{
          this.humanizeTimespans(this.timespan)
        }}) </small> </i>
      </h3>
    </div>
    <div class="stats-container-content">
      <v-table>
        <thead>
        <tr>
          <th class="text-left">
            Product
          </th>
          <th class="text-left">
            Count
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item) in this.transactions" :key="item.dimension_value">
          <td> {{ item.product }}</td>
          <td> {{ item.metric_value }}</td>
        </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import StatsMixin from '@/mixins/StatsMixin.vue';

export default defineComponent({
  name: 'StatsTransactions',
  data() {
    return {
      data: {
        metricBuckets: [],
      },
    };
  },
  props: {
    timespan: {
      type: String,
      default: 'total',
    },
    metrics: {
      type: Object,
    },
    rewindMetrics: {
      type: Object,
    },
  },
  methods: {},
  computed: {
    transactions() {
      const buckets = [];
      const timespan = this.normaliseTimespan(this.timespan);

      if (this.metrics.COUNT[timespan] !== undefined) {
        this.metrics.COUNT[timespan].forEach((metric) => {
          const m = metric;
          m.product = 'unknown';

          if (parseInt(m.dimension_value, 10) === 1) {
            m.product = 'Rewind';
          }
          buckets.push(m);
        });
      }
      const rewinds = this.rewindMetrics
        .COUNT[timespan][this.rewindMetrics.COUNT[timespan].length - 1];
      rewinds.metric_value = rewinds.dimension_value;
      rewinds.product = 'Rewind (free)';
      buckets.push(rewinds);

      return buckets;
    },
  },
  mixins: [
    StatsMixin,
  ],
  mounted() {
  },
  watch: {
    show(newVal) {
      console.log(newVal);
    },
    timespan(newVal) {
      console.log(newVal);
    },
  },
});
</script>

<style scoped>

</style>
